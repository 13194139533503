import styled from 'styled-components';

export const CentralContent = styled.div`
  position: relative;
  max-width: 1400px;
  margin-inline: auto;

  @media all and (max-width: 1440px) {
    & {
      max-width: 1200px;
    }
  }

  @media all and (max-width: 1240px) {
    & {
      max-width: 100%;
      padding-inline: 20px;
    }
  }
`;
