import { Fragment, useEffect } from 'react';
import styled from 'styled-components';
import { CentralContent, Footer, Header } from '../components';

const StyledPage = styled.div`
  padding-block: 36px;
  padding-top: 90px;

  h2 {
    margin-top: 36px;
  }

  p + p {
    margin-top: 24px;
  }

  p ul {
    padding-left: 20px;
    list-style-type: disclosure-closed;
  }

  .infos {
    margin-top: 20px;
    display: flex;
    gap: 60px;
  }
`

export const CGVPage = () => {

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 1);
  }, []);

  return (
    <Fragment>
      <Header />
      <StyledPage>
        <CentralContent>
          <h1>Conditions générales de vente</h1>

          <h2>1. Préambule</h2>
          <p>
          Toute commande implique de la part du client  l’acceptation pleine et entière de l’intégralité des Conditions Générales de Vente (C.G.V.) de la SAS TAN Les Parquets Lascazes (ci-après « Le vendeur »). Si l’une quelconque des clauses des présentes C.G.V. se révélait illégale pour quelque motif que ce soit, seule cette clause serait réputée non écrite, la convention étant maintenue intégralement pour tous ses autres effets.
          </p>

          <h2>2.  Formation du contrat</h2>
          <p>
          Le vendeur propose au client un devis valable UN mois ( sauf mention spécifique). Ce devis comporte la description de la prestation, son prix, ainsi que tout autre spécification convenue entre les parties.<br />
          Le contrat est soit une commande de fourniture de matériel, soit une commande de fourniture et pose, soit de pose seule.<br />
          La réception par le vendeur du devis signé par le client valide définitivement le contrat et emporte acceptation pure et simple des présentes C.G.V.<br />
          En cas d’annulation postérieure de la commande à la demande du client, quel qu’en soit le motif, celui-ci devra s’acquitter du paiement d’une indemnité correspondant à 40% du montant H.T. (Hors Taxes) de la commande. Les sommes éventuellement versées par le client à titre d’acompte s’imputeront sur cette somme.<br />
          Par ailleurs, toute commande de travaux supplémentaires devra faire l’objet d’un ou plusieurs devis  complémentaires dans les mêmes conditions. Toute modification de commande demandée par le client ne peut être examinée que si elle est parvenue par écrit au moins 24h avant le retrait, l'expédition ou la pose des marchandises. Si le vendeur n'accepte pas la modification demandée, la commande initiale sera exécutée, sauf à ce que le client l’annule, auquel cas il est fait références aux précédentes dispositions concernant l’annulation de commande.
          </p>

          <h2>3. Modalités d’exécution et délais</h2>
          <p>
          Le vendeur ne sera pas responsable des retards qui ne seraient pas dus à son fait personnel ou qui relèveraient de la force majeure. Il s’engage néanmoins à aviser le client de tout fait pouvant entraîner des retards et tentera les réduire.
          <br /><br />
          <br />
          Pour les commandes fourniture<br />
          Le vendeur informe l'acheteur de la date de mise à disposition (enlèvement ou livraison) de la marchandise. Pour les cas d’enlèvement, cette marchandise  doit être récupérée par le client dans les 5 jours suivant la mise à disposition. Passé ce délai, les frais de stockage lui seront facturés sans préjudice de toute action qu'entendra mener le vendeur.<br />
          <br />
          <br />
          Pour les commandes fourniture et pose ou pose seule<br />
          Pour les travaux de pose en extérieur, les délais convenus sont automatiquement prolongés de la durée des journées d'intempéries.
          </p>
          <h2>4. Responsabilité et Assurance</h2>
          <p>
          Le vendeur assume sous sa pleine et entière responsabilité l’exécution des travaux qui lui sont confiés, conformément aux règles de l’art ainsi qu’aux textes légaux et règlementaires applicables.
          <br /><br />

          Il est assuré pour sa responsabilité décennale suivant les termes de l’ article 1792 du Code Civil et sa responsabilité civile professionnelle, et ce pour l'ensemble de ses activités.
          </p>
          <h2>5. Garantie et responsabilité</h2>
          <p>
          Le vendeur ne saurait être tenu pour responsable des conséquences d’un stockage défectueux, d’une utilisation anormale, de l’utilisation ou de la pose dans des conditions anormales ou défectueuses de la marchandise. Il recommande au client de s’assurer, avant sa mise en œuvre, que la marchandise soit en bon état et corresponde aux spécifications de sa commande.<br />
          Le vendeur s’engage à livrer du matériel conforme en taille et dimensions aux échantillons choisis par le client. Toutefois, il est rappelé que de légères différences de teinte ou de consistance peuvent exister entre les échantillons présentés au client et ceux vendus. Le client renonce à élever toute contestation à ce titre.<br />
          Les vices apparents doivent être notifiés au vendeur par écrit, soit au moment de la prise de possession du matériel (enlèvement ou livraison), soit au moment de la réception des travaux, le tout conformément aux règles posées par le Code civil.<br />
          La garantie du vendeur porte sur les vices cachés et se limite au remplacement éventuel du matériel défectueux.<br />
          Il appartiendra à l'acheteur de fournir toute justification quant à la réalité des vices ou anomalies constatés. Il devra laisser au vendeur toute facilité pour procéder à la constatation de ces vices, éventuellement par voie d'expert, pour y porter remède. Il s'abstiendra d'intervenir lui-même ou de faire intervenir un tiers à cette fin sans l'accord préalable du vendeur, sauf dans ce cas à perdre le bénéfice de toute garantie.<br />
          Sont exclus de la garantie les défauts et détériorations provoqués par l'usure naturelle, par une modification de la marchandise non prévue ni spécifiée par le vendeur ou par l’intervention d’un tiers (entreprise de peinture…).<br />
          Les marchandises vendues nécessitent toujours que soient respectées les condition particulières d’entretien remises au client. Au titre de son devoir de conseil, le vendeur délivre habituellement ces informations avant même la formation du contrat. Si tel n’était pas le cas, le client s’engage à demander au vendeur ces informations, à tout moment et par tous moyens. Sauf à ce qu’il prouve qu’il n’aurait pas été fait droit à sa demande, les conseils d’entretien de la marchandise seront réputés reçus par le client, déchargeant le vendeur de la responsabilité encourue à ce titre.<br />
          Lorsque la pose de la marchandise implique la dépose de plinthes, celle-ci se fait sous la responsabilité pleine et entière du client. Cette opération pouvant altérer de manière plus ou moins importante la tenue du mur qui la reçoit, le vendeur n’acceptera d’y procéder que sous la condition d’une décharge de responsabilité expresse, laquelle résulte de l’acceptation des présentes C.G.V.
          </p>
          <h2>6. Conditions de paiement</h2>
          <p>
          Pour toutes factures relatives à de la fourniture seule tout règlement de solde de facture devra se faire obligatoirement par virement bancaire
          Le client devra procéder aux règlements suivants :
          <br />
          Pour les commandes fourniture
          <ul>
            <li>acompte de 50% au moment de la formation du contrat (devis accepté)</li>
            <li>solde de 50% à la mise en possession du parquet (enlèvement ou livraison)</li>
          </ul>
          <br />



          Pour les commandes fourniture et pose
          <ul>
            <li>acompte de 40% au moment de la formation du contrat (devis accepté)</li>
            <li>acompte de 40% à la livraison du parquet</li>
            <li>solde sur présentation de situation (intermédiaire ou finale)</li>
          </ul>
          <br />
          Pour les commandes pose seule
          <ul>
            <li>acompte de 80% au moment de la formation du contrat (devis accepté)</li>
            <li>solde de 20% à la réception.</li>
          </ul>
          L’utilisation par le client des lieux ou ont été effectué les travaux sans avoir établit de Procès Verbal de Réception vaut réception du chantier sans réserves. La date de réception de travaux est contractuellement fixée au jour de fin des travaux, le client étant tenu d’être présent afin de réceptionner les travaux. En cas d’intervention d’entreprises postérieurement à la fin des travaux le Client se doit de demander aux dites entreprises de protéger de manière efficace les travaux finis.
          En cas de retour de marchandises ( sauf clause contraire clairement énoncée), celui ci se fera avec une décote de 25% représentant les frais administratifs et de remise en stock.
          <br />
          </p>
          <h2>7. Retenue de garantie</h2>
          <p>
          Pour les commandes fourniture et pose ou pose seule, aucune retenue ne peut être effectuée par le client .
          </p>

          <h2>8. Pénalités de retard</h2>
          <p>
          En application des dispositions de la Loi n°2008-776 du 4 août 2008, à défaut de dispositions contractuelles dérogeant expressément aux présentes C.G.V., le règlement  des factures du vendeur sera exigible au plus tard 30 jours après émission de la facture, et ce tant pour les factures d’acomptes que du solde.<br />
          A compter du lendemain de la date d’exigibilité de chaque facture, le client sera automatiquement redevable de pénalités de retard au taux de 15% l’an, calculés sur le montant T.T.C. des sommes dont il est débiteur.<br />
          Ces pénalités seront immédiatement exigibles, sans qu’un rappel ne soit nécessaire. En outre, tous les frais de recouvrement justifiés par l’impayé du client (mise en demeure, frais d’huissier, frais d’avocat…) seront à sa charge exclusive.
          </p>
          <h2>9. Loi applicable</h2>
          <p>
          La Convention et les présentes Conditions Générales de Services sont soumises aux lois françaises.<br />
          En cas de litige relatif à leur conclusion, exécution ou résiliation, les Tribunaux de Marseille seront seuls compétents, même en cas de pluralité de défendeurs et d’appel en garantie.
          </p>
        </CentralContent>
      </StyledPage>
      <Footer />
    </Fragment>
  );
};
