import styled from 'styled-components';
import { FunctionComponent } from 'react';

const StyledComponent = styled.div`
    margin-bottom: 26px;

    h2 {
        font-weight: 300;
        font-size:44px;
        line-height: 50px;
        margin-bottom: 10px;
        text-align: center;
        font-family: 'Pacifico', cursive;

        span {
            font-weight: 400;
            font-family: 'Source Sans Pro', sans-serif;
        }
    }

    .separator {
        margin-inline: auto;
        width: 70px;
        height: 4px;
        background-color: #000;
    }

    &.white {
        color: #fff;
        text-align: center;

        .separator {
            background-color: #fff;
        }
    }
`;

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    color: 'white' | 'black',
}


export const SectionTitle: FunctionComponent<Props> = (
    {
        children,
        color = 'black',
        ...rest
    }: Props
): JSX.Element => (
    <StyledComponent
        className={`section-title ${color}`}
        {...rest}
    >
        <h2>
            {children}
        </h2>
        <div className='separator' />
    </StyledComponent>
);
