import styled from 'styled-components';
import { useRouteError } from 'react-router-dom';
import { StyledCentralContainer } from '../components/layout/Container';
import { CentralLayout } from '../components/layout';
import { Fragment } from 'react';
import { Header } from '../components';


type RouteError = {
  status?: number,
  statusText?: string,
  message?: string,
};

const StyledContainer = styled(StyledCentralContainer)`
    text-align: center;
    h1 {
        margin-top: 0;
    }
    p {
        margin-top: 0;
    }
    p.nb {
      font-size: 42px;
      font-weight: 700;
    }
`;

export const ErrorPage = () => {
  const error = useRouteError() as RouteError;
  console.error(error);

  return (
    <Fragment>
      <Header />
      <CentralLayout style={{ minHeight: '100Vh' }}>
      <StyledContainer>
        <h1>{ error.status ?? 'Oups !'}</h1>
        <p style={{ color: 'var(--default-gray)' }}>
          <i>{error.statusText || error.message || `${error}`}</i>
        </p>
        <p>Désolé, une erreur inattendue s'est produite.</p>
      </StyledContainer>
    </CentralLayout>
    </Fragment>
  );
};
