import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import { useEffect, useState } from "react";
import styled from "styled-components";
import SlickSlider from "react-slick";
import { CentralContent, CentralLayout } from "../layout";
import { TRUE_API_URL } from "../../constants";
import mask from "../../assets/slides/mask-slider.png";
import { LoadingPage } from "../../pages/LoadingPage";

const StyledComponent = styled.div`
  padding-block: 48px;
  padding-top: 0;
  padding-bottom: 0;

  @media all and (max-width: 1100px) {
    & {
      margin-top: 0;
      padding-bottom: 0;

      ${CentralContent} {
        padding-inline: 0;
      }

      .slick-dots {
        display: none !important;
      }
    }
  }

  .slick-prev {
    left: 10px;
    z-index: 5;
  }

  .slick-next {
    right: 38px;
    z-index: 5;
  }  
  
  .slick-next:before {
    font-size: 50px;
  }
  .slick-prev:before {
    font-size: 50px;
  }

  .slick-list {
    .slick-track {
      .slick-slide {
        font-size: 0;

        > div {
          position: relative;
          height: 100%;
        }
      }

      @media all and (max-width: 768px) {
        & {
          height: 240px;
        }
      }
    }
  }

  .slick-dots {
    bottom: 20px;

    li {
      z-index: 5;

      button::before {
        color: #fff;
      }
    }
  }

  .slide {
    position: relative;
    overflow: hidden;
    height: 100%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    img {
      position: relative;
      display: block;
      margin: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
    }

    .opacity {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.2);
    }

    .content {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      padding: 24px;
      color: #fff;
      font-size: 16px;
      text-align: center;

      ${CentralLayout} {
        max-width: 600px;
        margin-inline: auto;
      }

      .title {
        font-size: 36px;
        line-height: 50px;
        margin-bottom: 24px;
        position: relative;
        font-family: "Pacifico", cursive;
      }

      .description {
        position: relative;
        font-size: 18px;
      }
    }

    @media all and (max-width: 1100px) {
      .content {
        .title {
          margin-bottom: 0;
        }
        .description {
          display: none;
        }
      }
    }
  }
`;

interface ISlide {
  _id: string;
  title: string;
  description: string;
  imgPath: string;
}

export const Slider = () => {
  const [slides, setSlides] = useState<ISlide[]>([]);
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    axios
      .get(`${TRUE_API_URL}/api/caroussel`)
      .then((response: { data: { result: ISlide[] } }) => {
        setSlides(response.data.result);
        setLoaded(true);
      });
  }, []);

  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 720,
    slidesToShow: slides.length,
    slidesToScroll: slides.length,
    fade: true,
    autoplay: true,
    autoplaySpeed: 4000
  };

  return !loaded ? (
    <LoadingPage />
  ) : (
    <StyledComponent id="accueil" className="homepage-section">
      <SlickSlider {...settings}>
        {slides.map((slide: ISlide) => (
          <div className="slide" key={`slide-${slide._id}`}>
            <img
              alt={slide.title}
              src={mask}
              style={{
                backgroundImage: `url('${TRUE_API_URL}/${slide.imgPath}')`,
              }}
            />
            <div className="opacity" />
            <div className="content">
              <CentralLayout>
                <div className="title">{slide.title}</div>
                <div className="description">{slide.description}</div>
              </CentralLayout>
            </div>
          </div>
        ))}
      </SlickSlider>
    </StyledComponent>
  );
};
