import { createBrowserRouter } from "react-router-dom";
import {
  CGVPage,
  ErrorPage,
  Homepage,
  LegalMentionsPage,
  LoginPage,
  AdminPage,
} from "../pages";

export const ROUTES = {
  HOMEPAGE: "/",
  LEGAL_MENTIONS: "/mentions-legales/",
  CGV: "/conditions-generales-de-vente/",
  LOGIN: "/login/",
  ADMIN: "/admin/",
};

export const router = createBrowserRouter([
  {
    path: ROUTES.HOMEPAGE,
    element: <Homepage />,
    errorElement: <ErrorPage />,
  },
  {
    path: ROUTES.LOGIN,
    element: <LoginPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: ROUTES.ADMIN,
    element: <AdminPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: ROUTES.LEGAL_MENTIONS,
    element: <LegalMentionsPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: ROUTES.CGV,
    element: <CGVPage />,
    errorElement: <ErrorPage />,
  },
]);
