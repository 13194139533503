import axios from "axios";
import { useEffect, useState } from "react";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from "styled-components";
import { CentralContent, CentralLayout } from "../layout";
import facebookLogo from "../../assets/social/facebook.png";
import instagramLogo from "../../assets/social/instagram.png";
import mask from "../../assets/social/mask-instagram.png";
import { SectionTitle } from "../global";
import { TRUE_API_URL } from "../../constants";

const StyledComponent = styled.div`
  padding-block: 48px;
  background-color: #f9eae1;

  @media all and (max-width: 768px) {
    & {
      padding-block: 36px;
    }
  }

  h4 {
    margin-top: 18px;
    text-align: center;
    margin-bottom: 16px;
  }

  .content-networks {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 36px;
    margin-bottom: 24px;

    img {
      height: 36px;
      display: block;
    }
  }

  .content-posts {
    position: relative;
    max-width: 935px;
    margin: 24px auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 4px;

    .post {
      flex: 1 0 33%;
      background: #000;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
      }

      .hover {
        opacity: 0;
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.3);
        transition: all 0.3s ease;

        .text {
          color: #fff;
          font-size: 18px;
        }
      }

      &:hover {
        .hover {
          opacity: 1;
        }
      }

      @media all and (max-width: 835px) {
        &:nth-child(9) {
          display: none;
        }
      }

      @media all and (max-width: 650px) {
        & {
          flex: 1 0 49%;
        }
      }
    }
  }
`;

interface IinstagramPosts {
  postsUrl: string;
  imgUrl: string;
  caption: string;
  likes: number;
}

async function getInstagramPosts(): Promise<IinstagramPosts[]> {
  const url = `${TRUE_API_URL}/api/instagram/`;
  const response = await axios.get<IinstagramPosts[]>(url);
  return response.data;
}

export const SocialNetworks = () => {
  const [instagramPosts, setInstagramPosts] = useState<IinstagramPosts[] | []>(
    []
  );

  useEffect(() => {
    (async () => {
      const instagramPosts = await getInstagramPosts();
      setInstagramPosts(instagramPosts);
    })();
  }, []);

  return (
    <StyledComponent id="nos-reseaux" className="homepage-section">
      <CentralContent>
        <SectionTitle color="black">
          <span>Nos</span> réseaux
        </SectionTitle>
        <h4>Suivez nos dernières actualités :</h4>
        <div className="content-networks">
          <a
            href="https://www.facebook.com/parquetlascazes/"
            target="_blank"
            title="Facebook"
            rel="noreferrer"
          >
            <img src={facebookLogo} alt="" />
          </a>
          <a
            href="https://www.instagram.com/parquetlascazes/?hl=fr"
            target="_blank"
            title="Instagram"
            rel="noreferrer"
          >
            <img src={instagramLogo} alt="" />
          </a>
        </div>
        <div className="content-posts">
          {instagramPosts.map((post, index) => (
            <div className="post" key={`post-${index}`}>
              <a href={post.postsUrl} target="_blank" rel="noreferrer">
                <img
                  alt=""
                  src={mask}
                  style={{
                    backgroundImage: `url('${TRUE_API_URL}/${post.imgUrl}')`,
                  }}
                />
                <div className="hover">
                  <CentralLayout>
                    <div className="text">Voir sur Instagram</div>
                  </CentralLayout>
                </div>
              </a>
            </div>
          ))}
        </div>
      </CentralContent>
    </StyledComponent>
  );
};
