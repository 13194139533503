import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { API_URL, TRUE_API_URL } from "../../constants";
import { LoadingPage } from "../../pages/LoadingPage";
import Modal from "react-modal";
import { MDBTable, MDBTableHead, MDBTableBody, MDBBtn } from "mdb-react-ui-kit";

const StyledAdmin = styled.div`
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  margin-top: 100px;
  padding-left: 16px;

  .table {
    width: 100%;
    text-align: center;
  }
`;

const AdminItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  img {
    width: 150px; // Adjust the width as needed
    height: auto; // Adjust the height as needed
    margin-right: 16px;
  }

  h3 {
    margin-right: 16px;
  }

  button {
    margin-right: 8px;
  }
`;

// Style for the modal
const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Center vertically */

  h2 {
    margin-bottom: 16px;
  }

  form {
    width: 100%; /* Set the form width to 100% */
    max-width: 500px; /* Adjust the maximum width as needed */
  }

  label {
    margin-top: 8px;
  }

  input {
    margin-bottom: 16px;
    width: 100%; /* Make the input full width */
  }

  button {
    margin-top: 16px;
    cursor: pointer;
  }
`;

interface Slide {
  _id: string;
  title: string;
  imgPath: string;
  description: string;
}

export const Admin = () => {
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);
  const [slides, setSlides] = useState<Slide[]>([]);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
  const [editedSlide, setEditedSlide] = useState<Slide | null>(null);

  const [formData, setFormData] = useState<FormData>(new FormData());

  let base_url = "";
  if (process.env.NODE_ENV === "development") {
    base_url = "http://localhost:3002";
  } else {
    base_url = "https://back-baqz.onrender.com";
  }

  useEffect(() => {
    // Call checkToken when the component is mounted
    checkToken();
  }, []);

  const checkToken = async () => {
    try {
      let user: any | null = null;
      let header: any = null;
      if (typeof localStorage !== "undefined") {
        const storedUser = localStorage.getItem("user");
        // Check if storedUser is not null before parsing
        if (storedUser !== null) {
          user = JSON.parse(storedUser);
        }
      }
      if (user && user.token) {
        header = Object.assign({ "x-access-token": user.token });
      }
      await axios.post(`${base_url}/api/users/check-token`, user, {
        headers: header,
      });

      await fetchSlider();
      setLoaded(true);
    } catch (error) {
      console.log(error);
      navigate("/"); // Navigate to "/" on error
    }
  };

  const fetchSlider = async () => {
    try {
      const response = await axios.get(`${base_url}/api/caroussel`);
      setSlides(response.data.result);
    } catch (error) {
      console.log(error);
    }
  };

  const openModal = (slide?: Slide) => {
    if (slide) {
      setEditedSlide(slide);
      setEditModalIsOpen(true);
    } else {
      setCreateModalIsOpen(true);
    }
  };

  const closeModal = () => {
    setEditedSlide(null);
    setEditModalIsOpen(false);
    setCreateModalIsOpen(false);
  };

  const handleEditFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const config = {
      headers: { "Content-Type": "multipart/form-data" },
    };

    let user: any | null = null;
    let header: any = null;
    if (typeof localStorage !== "undefined") {
      const storedUser = localStorage.getItem("user");
      // Check if storedUser is not null before parsing
      if (storedUser !== null) {
        user = JSON.parse(storedUser);
      }
    }

    if (user && user.token) {
      header = Object.assign({ "x-access-token": user.token }, config.headers);
    }
    //TO DO change in service
    await axios.put(`${base_url}/api/caroussel/${editedSlide?._id}`, formData, {
      headers: header,
    });
    // Implement your logic for form submission, e.g., update the slide
    // window.location.reload();
    fetchSlider();
    setFormData(new FormData());
    closeModal();
  };

  const handleCreateFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const config = {
      headers: { "Content-Type": "multipart/form-data" },
    };

    let user: any | null = null;
    let header: any = null;
    if (typeof localStorage !== "undefined") {
      const storedUser = localStorage.getItem("user");
      // Check if storedUser is not null before parsing
      if (storedUser !== null) {
        user = JSON.parse(storedUser);
      }
    }

    if (user && user.token) {
      header = Object.assign({ "x-access-token": user.token }, config.headers);
    }

    //TO DO change in service
    await axios.post(`${base_url}/api/caroussel/`, formData, {
      headers: header,
    });
    // Implement your logic for form submission, e.g., update the slide
    fetchSlider();
    setFormData(new FormData());
    closeModal();
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    formData.set(name, value);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      formData.append("image", file);
    }
  };

  const deleteSlide = async (id: string) => {
    try {
      const config = {
        headers: { "Content-Type": "multipart/form-data" },
      };

      let user: any | null = null;
      let header: any = null;
      if (typeof localStorage !== "undefined") {
        const storedUser = localStorage.getItem("user");
        // Check if storedUser is not null before parsing
        if (storedUser !== null) {
          user = JSON.parse(storedUser);
        }
      }
      if (user && user.token) {
        header = Object.assign({ "x-access-token": user.token });
      }

      await axios.delete(`${base_url}/api/caroussel/${id}`, {
        headers: header,
      });
      fetchSlider();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <StyledAdmin>
      {loaded ? (
        <div className="">
          <MDBBtn
            color="link"
            onClick={() => openModal()}
            // rounded="true"
            size="sm"
          >
            Ajouter une slide
          </MDBBtn>
          <br />
          <br />
          <MDBTable className="table">
            <MDBTableHead>
              <tr>
                <th scope="col">Image</th>
                <th scope="col">Titre</th>
                <th scope="col">Description</th>
                <th scope="col">Action</th>
              </tr>
              <br />
            </MDBTableHead>
            <MDBTableBody>
              {slides.map((item: Slide) => (
                <>
                  <tr id={item._id} key={item._id} className="product-raw">
                    <td>
                      <span className="d-flex align-items-center">
                        <img
                          src={`${TRUE_API_URL}/${item.imgPath}`}
                          alt=""
                          style={{ width: "100px", height: "85px" }}
                          className="rounded-circle"
                        />
                      </span>
                    </td>
                    <td style={{ verticalAlign: "middle" }}>
                      <p className="fw-normal mb-1">{item.title}</p>
                    </td>
                    <td style={{ verticalAlign: "middle" }}>
                      <p className="fw-normal mb-1">{item.description}</p>
                    </td>
                    <td style={{ verticalAlign: "middle" }}>
                      <MDBBtn
                        onClick={() => openModal(item)}
                        color="link"
                        // rounded="true"
                        size="sm"
                      >
                        Edit
                      </MDBBtn>
                      <MDBBtn
                        className="margin-left-10"
                        onClick={() => deleteSlide(item._id)}
                        color="link"
                        // rounded="true"
                        size="sm"
                      >
                        Delete
                      </MDBBtn>
                    </td>
                  </tr>
                </>
              ))}
            </MDBTableBody>
          </MDBTable>
        </div>
      ) : (
        <LoadingPage />
      )}
      <Modal
        isOpen={editModalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Edit Slide Modal"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            padding: "20px",
          },
        }}
      >
        <ModalWrapper>
          <button onClick={closeModal}>&times;</button>
          <h2>Edit Slide</h2>
          <form onSubmit={handleEditFormSubmit} encType="multipart/form-data">
            <label>Title:</label>
            <input type="text" name="title" onChange={handleInputChange} />
            <label>Description:</label>
            <input
              type="text"
              name="description"
              onChange={handleInputChange}
            />
            <label>Position:</label>
            <input
              type="text"
              name="pos"
              onChange={handleInputChange}
            />
            <label>Upload Image:</label>
            <input
              type="file"
              accept="image/*"
              name="image"
              onChange={handleFileChange}
            />
            <button onClick={(e) => handleEditFormSubmit(e)} type="submit">
              Save Changes
            </button>
          </form>
          <button onClick={closeModal}>Close</button>
        </ModalWrapper>
      </Modal>
      {/* CREATE MODAL ----------------------------------------------------- */}
      <Modal
        isOpen={createModalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Create Slide Modal"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            padding: "20px",
          },
        }}
      >
        <ModalWrapper>
          <button onClick={closeModal}>&times;</button>
          <h2>Create Slide</h2>
          <form onSubmit={handleCreateFormSubmit} encType="multipart/form-data">
            <label>Title:</label>
            <input type="text" name="title" onChange={handleInputChange} />
            <label>Description:</label>
            <input
              type="text"
              name="description"
              onChange={handleInputChange}
            />
            <label>Position:</label>
            <input
              type="text"
              name="pos"
              onChange={handleInputChange}
            />
            <label>Upload Image:</label>
            <input
              type="file"
              accept="image/*"
              name="image"
              onChange={handleFileChange}
            />
            {/* {/* Add logic to handle file upload */}
            <button onClick={(e) => handleCreateFormSubmit(e)} type="submit">
              Ajouter slide
            </button>
          </form>
          <button onClick={closeModal}>Close</button>
        </ModalWrapper>
      </Modal>
    </StyledAdmin>
  );
};
