import { Fragment } from "react";
import { Footer, Header, Login } from "../components";

export const LoginPage = () => (
  <Fragment>
    <Header />
    <Login />
    <Footer />
  </Fragment>
);
