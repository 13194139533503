import Modal from "react-modal";
import styled from "styled-components";
// // Style for the modal
const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Center vertically */

  h2 {
    margin-bottom: 16px;
  }

  form {
    width: 100%; /* Set the form width to 100% */
    max-width: 400px; /* Adjust the maximum width as needed */
  }

  label {
    margin-top: 8px;
  }

  input {
    margin-bottom: 16px;
    width: 100%; /* Make the input full width */
  }

  button {
    margin-top: 16px;
    cursor: pointer;
  }
`;

interface Slide {
  _id: string;
  title: string;
  imgPath: string;
  description: string;
}

export const ModalEdit = (
  modalIsOpen: any,
  closeModal: any,
  handleFormSubmit: any,
  handleInputChange: any,
  handleFileChange: any,
  formData: any
) => {
  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Edit Slide Modal"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            padding: "20px",
          },
        }}
      >
        <ModalWrapper>
          <button onClick={closeModal}>&times;</button>
          <h2>Edit Slide</h2>
          <form onSubmit={handleFormSubmit}>
            <label>Title:</label>
            <input
              type="text"
              name="title"
              value={formData.title || ""}
              onChange={handleInputChange}
              // Add onChange handler to update the editedSlide state
            />
            <label>Description:</label>
            <input
              type="text"
              name="description"
              value={formData.description || ""}
              onChange={handleInputChange}
              // Add onChange handler to update the editedSlide state
            />
            <label>Position</label>
            <input
              type="text"
              name="pos"
              value={formData.pos || ""}
              onChange={handleInputChange}
              // Add onChange handler to update the editedSlide state
            />
            <label>Upload Image:</label>
            <input type="file" accept="image/*" onChange={handleFileChange} />
            {/* Add logic to handle file upload */}
            <button onClick={(e) => handleFormSubmit(e)} type="submit">
              Save Changes
            </button>
          </form>
          <button onClick={closeModal}>Close</button>
        </ModalWrapper>
      </Modal>
    </>
  );
};
