import { Fragment } from "react";
import { Admin, Footer, Header } from "../components";

export const AdminPage = () => (
  <Fragment>
    <Header />
    <Admin />
    <Footer />
  </Fragment>
);
