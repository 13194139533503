import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";
import dayjs from "dayjs";
import { Rating } from "react-simple-star-rating";
import google from "../../assets/social/google.png";
import { CentralContent } from "../layout";
import { SectionTitle } from "../global";
import STATIC_DATA from "../../fake/google-reviews.json";
import { TRUE_API_URL } from "../../constants";

const NEW_REVIEW_LINK =
  "https://www.google.com/search?hl=fr-FR&gl=fr&q=Parquet+Lascazes+-+Aubagne,+180+Av.+de+Jouques,+13400+Aubagne&ludocid=15043152837223155588&lsig=AB86z5W_HETY0-50IrO0KsPSUmCJ&hl=fr&gl=FR&bshm=rime/1#lrd=0x12c9a37567e6dde9:0xd0c403cdac988784,3,,,,";

const StyledComponent = styled.div`
  background-color: #ffffff;
  padding-block: 48px;

  @media all and (max-width: 768px) {
    & {
      padding-block: 36px;
    }
  }

  h4 {
    margin-top: 18px;
    text-align: center;
    margin-bottom: 16px;
  }

  .content-header {
    padding: 24px;
    background-color: rgba(17, 17, 17, 0.05);
    border-radius: 8px;
    margin-block: 24px;

    .content {
      position: relative;
      display: flex;
      align-items: center;

      .left {
        .title {
          span {
            display: inline-block;
            vertical-align: middle;
            font-size: 24px;
            font-weight: 500;
            padding-right: 12px;
          }
          img {
            width: 100px;
            display: inline-block;
            vertical-align: middle;
          }
        }

        .rating {
          span {
            font-size: 16px;
          }

          .value {
            font-size: 22px;
            font-weight: 700;
            padding-right: 10px;
          }

          .nb {
            font-size: 14px;
            padding-left: 10px;
          }
        }
      }

      .right {
        margin-left: auto;

        a {
          text-decoration: none;
          padding: 12px;
          background: rgb(25, 123, 255);
          color: #fff;
          transition: all 0.4s ease;
          border-radius: 4px;
          display: inline-block;

          &:hover {
            opacity: 0.9;
          }
        }
      }

      @media all and (max-width: 450px) {
        & {
          flex-direction: column;
          align-items: start;

          .right {
            margin-left: 0;
            margin-top: 14px;
          }
        }
      }
    }
  }

  .content-reviews {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 12px;

    .google-review {
      flex: 1 0 21%;
      padding: 24px;
      background-color: rgba(17, 17, 17, 0.05);
      border-radius: 8px;

      .author {
        position: relative;
        display: flex;

        img {
          width: 40px;
          height: 40px;
        }

        .content {
          margin-left: 12px;

          .name {
            font-size: 16px;
            line-height: 18px;
            font-weight: 700;
          }
          .date {
            margin-top: 4px;
            font-size: 14px;
            line-height: 16px;
            color: rgba(17, 17, 17, 0.5);
          }
        }
      }

      .rate {
        margin-block: 10px;
      }

      .text {
        font-size: 16px;
      }

      @media all and (max-width: 1240px) {
        & {
          flex: 1 0 32%;
        }
      }

      @media all and (max-width: 968px) {
        & {
          flex: 1 0 49%;
        }
      }
    }
  }
`;

interface IResult {
  rating: number;
  user_ratings_total: number;
  reviews: IReview[];
}

interface IResponse {
  _id: string;
  reviewsJson: string;
}
interface IReview {
  time: number;
  profile_photo_url: string;
  author_name: string;
  rating: number;
  text: string;
}

export const Reviews = () => {
  const [result, setResult] = useState<IResult>({
    rating: 0,
    user_ratings_total: 0,
    reviews: [],
  });
  useEffect(() => {
    axios
      .get(`${TRUE_API_URL}/api/googleReview`)
      .then((response: { data: IResponse[] }) => {
        const resultJson: IResult = JSON.parse(response.data[0].reviewsJson);
        setResult(resultJson);
      })
      .catch((e) => {
        setResult(STATIC_DATA);
      });
  }, []);

  return (
    <StyledComponent id="avis" className="homepage-section">
      <CentralContent>
        <SectionTitle color="black">
          <span>Vos</span> avis
        </SectionTitle>
        <h4>Ce que nos clients disent de nous :</h4>
        <div className="content-header">
          <div className="content">
            <div className="left">
              <div className="title">
                <span>Avis</span>
                <img alt="Google" src={google} />
              </div>
              <div className="rating">
                <span className="value">{result.rating}</span>
                <Rating readonly initialValue={result.rating} size={22} />
                <span className="nb">{result.user_ratings_total} avis</span>
              </div>
            </div>
            <div className="right">
              <a href={NEW_REVIEW_LINK} target="_blank" rel="noreferrer">
                Écrire un avis
              </a>
            </div>
          </div>
        </div>
        <div className="content-reviews">
          {result.reviews.map((review: IReview) => (
            <div className="google-review" key={review.time}>
              <div className="author">
                <img alt="" src={review.profile_photo_url} />
                <div className="content">
                  <div className="name">{review.author_name}</div>
                  <div
                    className="date"
                    title={dayjs.unix(review.time).format("DD MMMM YYYY")}
                  >
                    {dayjs().to(dayjs.unix(review.time))}
                  </div>
                </div>
              </div>
              <div className="rate">
                <Rating readonly initialValue={review.rating} size={20} />
              </div>
              <div className="text">{review.text}</div>
            </div>
          ))}
        </div>
      </CentralContent>
    </StyledComponent>
  );
};
