import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import styled from "styled-components";
import { TRUE_API_URL } from "../../constants";

const StyledLogin = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .login-container {
    max-width: 400px;
    padding: 24px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #fff;

    h2 {
      text-align: center;
      margin-bottom: 24px;
    }

    label {
      display: block;
      margin-bottom: 8px;
    }

    input {
      width: 100%;
      padding: 8px;
      margin-bottom: 16px;
      border: 1px solid #ccc;
      border-radius: 4px;
    }

    button {
      width: 100%;
      padding: 12px;
      background-color: #007bff;
      color: #fff;
      border: none;
      border-radius: 4px;
      cursor: pointer;
    }
  }
`;

export const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);

  const navigate = useNavigate();

  const handleLogin = () => {
    // Add your login logic here
    axios
      .post(`${TRUE_API_URL}/api/users/login`, {
        username: username,
        passwd: password,
      })
      .then((response) => {
        // Handle successful login
        const data = response.data.retUser;
        localStorage.setItem("user", JSON.stringify(data));
        navigate("/admin");
      })
      .catch((error) => {
        // Handle login error
        setError(true);
        console.error("Login error:", error.message);
      });
  };

  return (
    <StyledLogin>
      <div className="login-container">
        <h2>Login</h2>
        <label htmlFor="username">Username:</label>
        <input
          type="text"
          id="username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <label htmlFor="password">Password:</label>
        <input
          type="password"
          id="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        {error ? (
          <div style={{ color: "red" }}>
            Username ou mot de passe incorrecte !
          </div>
        ) : null}
        <button onClick={handleLogin}>Login</button>
      </div>
    </StyledLogin>
  );
};
