import { Fragment } from "react";
import {
  Contact,
  Footer,
  Header,
  SocialNetworks,
  Reviews,
  Slider,
} from "../components";
// import { LoadingPage } from "./LoadingPage";

export const Homepage = () => (
  <Fragment>
    <Header />
    <Slider />
    <SocialNetworks />
    <Contact />
    <Reviews />
    <Footer />
  </Fragment>
);
