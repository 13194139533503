import styled from 'styled-components';
import { CentralLayout } from '../components/layout';
import { useEffect, useState } from 'react';



const StyledContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  background-color: #fff;
  margin: 0;
  padding: 0;
  z-index: 99999;
  transition: opacity 0.4s ease;

  &.loaded {
    opacity: 0;
  }

  .loader {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    background: linear-gradient(0deg, rgba(94,168,74, 0.2) 33%, var(--color-green) 100%);
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
  .loader::after {
    content: '';  
    box-sizing: border-box;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background: #fff;
  }
  @keyframes rotation {
    0% { transform: rotate(0deg) }
    100% { transform: rotate(360deg)}
  } 
`;

export const LoadingPage = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isFullyLoaded, setIsFullyLoaded] = useState(false);

  useEffect(() => {
    const onLoad: EventListener = (event: Event) => {
      setIsLoaded(true);
      setTimeout(() => {
        setIsFullyLoaded(true);
      }, 500);
    };
    const win: Window = window; 
    win.addEventListener("load", onLoad);

    return () => window.removeEventListener("load", onLoad);
  }, []);

  if (isFullyLoaded) {
    return null;
  }

  return (
      <StyledContainer className={isLoaded ? 'loaded' : ''}>
        <CentralLayout>
          <span className='loader' />
        </CentralLayout>
      </StyledContainer>
  );
};
