import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from "styled-components";
import { CentralContent } from "../layout";
import { SectionTitle } from "../global";

const StyledComponent = styled.div`
  padding-block: 48px;
  background-color: #f2f9f1;

  @media all and (max-width: 768px) {
    & {
      padding-block: 36px;
    }
  }

  h4 {
    margin-top: 18px;
    text-align: center;
    margin-bottom: 16px;
  }

  .places-list {
    ul {
      list-style-type: none;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 20px;
      margin-bottom: 24px;

      li {
        display: inline-block;
        vertical-align: middle;
        color: #fff;
        font-size: 20px;
        cursor: pointer;
        border-bottom: 4px solid transparent;
        padding-inline: 6px;
        padding-block: 4px;
        transition: border-color 0.4s ease;

        &:hover,
        &.active {
          border-color: #fff;
        }
      }

      @media all and (max-width: 460px) {
        & {
          flex-direction: column;
          gap: 10px;
        }
      }
    }
  }

  .place {
    .content {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 36px;

      > div {
        flex: 50%;
      }

      .map {
        margin-top: 22px;
        height: calc(100% - 22px);
        background: #f3f3f3;
        display: flex;
        position: relative;
        color: #000;
        justify-content: center;
        align-items: center;

        iframe {
          border: 0;
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }

        @media all and (max-width: 960px) {
          & {
            height: 300px;
          }
        }
      }
    }

    .title {
      font-size: 28px;
      line-height: 32px;
      font-weight: 600;
    }

    .subtitle {
      font-size: 24px;
      line-height: 28px;
      margin-top: 22px;
      margin-bottom: 12px;
      font-weight: 600;

      @media all and (max-width: 960px) {
        &:first-child {
          margin-top: 0;
        }
      }
    }

    @media all and (max-width: 960px) {
      .content {
        flex-direction: column;

        > div {
          flex: 100%;
        }
      }
    }
  }
`;

export const Contact = () => {
  const place = {
    key: "aubagne",
    title: "Aubagne",
    phone: "04 42 70 01 61",
    address: "180 avenue de Jouques, 13400 Aubagne",
    mail: "jd@groupetan.com",
    hours: (
      <p>
        Du lundi au samedi
        <br />
        de 9h00 à 12h00 et de 14h00 à 18h00
      </p>
    ),
    description: (
      <p>
        Notre magasin et son show-room de plus de 200 m2 qui jouxte notre dépôt
        où sont entreposés plusieurs milliers de mètres carrés de parquets et
        terrasses disponibles immédiatement, est situé dans la Zone Industrielle
        des Paluds à quelques encablures du centre AGORA.
        <br />
        <br />
        Vous pourrez y choisir toute une gamme de produits d’intérieur : parquet
        massif, parquet contrecollé, stratifié, revêtement vinyle, produits de
        terrasse extérieure bois et composite et un large choix de solutions de
        placard et dressing.
        <br />
        <br />
        Sur place, notre équipe de vente pourra immédiatement vous établir un
        devis détaillé correspondant à votre projet.
        <br />
        <br />
        Un parking tout spécialement dédié à notre clientèle facilitera votre
        visite.
      </p>
    ),
  };

  return (
    <StyledComponent id="contact" className="homepage-section">
      <CentralContent>
        <SectionTitle color="black">
          <span>Nous</span> contacter
        </SectionTitle>
        <h4>Venez nous voir en magasin :</h4>
        <div className="place">
          <div className="title">Magasin {place.title}</div>
          <div className="content">
            <div>
              <div className="map">
                <iframe
                  title={`Magasin ${place.title}`}
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2904.2704741171087!2d5.600336612448458!3d43.28765717100133!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12c9a37567e6dde9%3A0xd0c403cdac988784!2sParquet%20Lascazes%20-%20Aubagne!5e0!3m2!1sfr!2sfr!4v1697273297874!5m2!1sfr!2sfr"
                  loading="lazy"
                />
              </div>
            </div>
            <div>
              <div className="subtitle">Adresse</div>
              <p>{place.address}</p>
              <p>
                <b>tel.:</b> {place.phone}
              </p>
              <p>
                <b>mail :</b> {place.mail}
              </p>
              <div className="subtitle">Horaire</div>
              <p>{place.hours}</p>
              <div className="subtitle">Description</div>
              <p>{place.description}</p>
            </div>
          </div>
        </div>
      </CentralContent>
    </StyledComponent>
  );
};
