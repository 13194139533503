import styled from 'styled-components';

export const BlockContainer = styled.div`
  background: transparent;
  box-flex: 1;
  padding: 24px;

  &.loading {
    opacity: 0.25;
    transition: opacity 200ms;
    transition-delay: 200ms;
  }
  
`;