import { Fragment, useEffect } from 'react';
import styled from 'styled-components';
import { CentralContent, Footer, Header } from '../components';

const StyledPage = styled.div`
  padding-block: 36px;
  padding-top: 90px;

  h2 {
    margin-top: 36px;
  }

  p + p {
    margin-top: 24px;
  }

  p ul {
    padding-left: 20px;
    list-style-type: disclosure-closed;
  }

  .infos {
    margin-top: 20px;
    display: flex;
    gap: 60px;
  }
`

export const LegalMentionsPage = () => {

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 1);
  }, []);

  return (
    <Fragment>
      <Header />
      <StyledPage>
        <CentralContent>
          <h1>Mentions légales</h1>

          <h2>Décharge de responsabilité</h2>
          <p>
            Parquet Lascazes s’est efforcé d’assurer l’exactitude de l’ensemble des informations fournies sur son site.<br />
            La responsabilité de Parquet Lascazes ne pourra en aucun cas être engagée quant à d’éventuelles erreurs ponctuelles et inopinées pouvant survenir sur le “site”.<br />
            Parquet Lascazes décline toute responsabilité concernant les informations disponibles sur son “site”. De plus, aucune garantie n’est donnée quant à l’exactitude, la précision ou l’exhaustivité des informations mises à disposition sur le “site”.<br />
            Parquet Lascazes ne garantit pas le fait que le serveur accueillant le “site” soit exempt de virus ou qu’il puisse survenir un problème technique qui pourrait endommager les composants de l’ordinateur de l’utilisateur ou des données qui pourraient y être stockées. En tout état de cause Parquet Lascazes ou un de ses sous-traitant ne pourra être responsable d’un dommage quelconque pouvant se produire lors de la connexion sur le “site”.
          </p>

          <h2>Copyright</h2>
          <p>
            Aucune licence, ni aucun autre droit que celui de consulter le “site”, n’est conférée à quiconque au regard des droits de propriété intellectuelle. La reproduction des documents du “site” est autorisée aux fins exclusives d’information pour un usage personnel et privé ; toute reproduction ou toute utilisation de copies réalisées à d’autres fins est expressément interdite.
          </p>

          <h2>Politique de protection des données personnelles</h2>
          <p>
            La société Parquet Lascazes s’engage à ce que la collecte et le traitement de vos données, effectués à partir du site https://parquet-lascazes.fr/, soient conformes au règlement général sur la protection des données (RGPD) et à la loi Informatique et Libertés.<br />
            Chaque formulaire ou téléservice limite la collecte des données personnelles au strict nécessaire (minimisation des données) et indique notamment :
            <ul>
              <li>
                quels sont les objectifs du recueil de ces données (finalités) ;
              </li>
              <li>
                si ces données sont obligatoires ou facultatives pour la gestion de votre demande ;
              </li>
              <li>
                qui pourra en prendre connaissance (uniquement Parquet Lascazes en principe, sauf précision dans le formulaire lorsqu’une transmission à un tiers est nécessaire à la gestion de votre demande) ;
              </li>
            </ul>
          </p>
          <p>
            Les données personnelles recueillies dans le cadre des services proposés sur https://parquet-lascazes.fr/ sont traitées selon des protocoles sécurisés et permettent à Parquet Lascazes de gérer les demandes reçues dans ses applications informatiques.<br />
            Pour toute information ou exercice de vos droits Informatique et Libertés sur les traitements de données personnelles gérés par Parquet Lascazes, vous pouvez contacter son délégué à la protection des données (DPO) :<br />
            <ul>
              <li>par mail à lascazes@groupetan.com</li>
              <li>ou par courrier signé accompagné de la copie d’un titre d’identité à l’adresse suivante :</li>
            </ul>
          </p>

          <div className='infos'>
            <div className="column">
              <p>
                <b>Parquet Lascazes</b>
                <br />
                ZI Les Paluds
                <br />
                180 Avenue de Jouques
                <br />
                13400 Aubagne
                <br /><br />
                <b>Tél. :</b> +33 (0)4 42 70 01 61
                <br />
                <b>Fax. :</b> +33 (0)4 42 82 13 28
              </p>
            </div>
            <div className="column">
              <p>
                <b>SIRET :</b><br />
                49985121000017
                <br /><br /><br /><br />
                <b>Représentant légal :</b>
                <br />
                Bernard DEBOUTIN
              </p>
            </div>
          </div>
        </CentralContent>
      </StyledPage>
      <Footer />
    </Fragment>
  );
};
