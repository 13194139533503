import styled from "styled-components";
import { CentralContent } from "./layout";
import logo from "../assets/logo.png";
import { Link } from "react-router-dom";
import { ROUTES } from "../config";
import { useEffect, useMemo, useState } from "react";
import { MenuBurgerButton } from "./MenuBurgerButton";

const StyledHeader = styled.header`
  background-color: rgba(255, 255, 255, 0);
  transition: background-color 0.4s ease;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  display: block;

  .content {
    position: relative;
    display: flex;

    .left {
      padding-block: 20px;

      a {
        display: block;
        text-decoration: none;

        img {
          display: block;
          height: 45px;
        }
      }
    }

    .right {
      margin-left: auto;
      display: flex;
      align-items: center;

      @media all and (min-width: 561px) {
        .menu-burger-button {
          display: none;
        }
      }

      ul {
        list-style-type: none;
        display: flex;
        height: 100%;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        align-items: stretch;

        li {
          display: flex;
          background: transparent;
          position: relative;
          align-items: stretch;
          transition: background-color 0.4s ease;
          cursor: pointer;

          a {
            display: flex;
            padding-inline: 20px;
            text-decoration: none;
            color: #1a1a1a;
            transition: color 0.4s ease;
            font-weight: 500;
            width: 100%;
            align-items: center;
            color: #fff;
          }

          &:hover,
          &.active {
            a {
              color: var(--color-green);
            }
          }
        }

        @media all and (max-width: 560px) {
          & {
            display: flex;
            position: fixed;
            background: #fff;
            top: 70px;
            bottom: 0;
            right: -540px;
            width: 300px;
            max-width: calc(100% - 20px);
            flex-direction: column;
            align-items: initial;
            justify-content: initial;
            padding-block: 20px;
            transition: right 0.7s ease;
            gap: 0;

            li {
              a {
                padding: 10px 20px;
                color: #1a1a1a;
              }
            }
          }

          &.opened {
            right: 0;
            transition: right 0.7s ease;
          }
        }
      }
    }
  }

  @media all and (max-width: 560px) {
    &.opened {
      background-color: rgba(255, 255, 255, 1);
    }
  }

  &.scrolled {
    background-color: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(6px);

    @media all and (max-width: 560px) {
      & {
        background-color: rgba(255, 255, 255, 1);
        backdrop-filter: none;
      }
    }

    .right {
      ul {
        li {
          a {
            color: #1a1a1a;
          }
          &:hover,
          &.active {
            a {
              color: var(--color-green);
            }
          }
        }
      }
    }
  }
`;

export const Header = () => {
  const [opened, setOpened] = useState(false);

  const [scrolled, setSrolled] = useState(false);
  const homepageUrl = useMemo(
    () => (window.location.pathname !== ROUTES.HOMEPAGE ? ROUTES.HOMEPAGE : ""),
    []
  );

  const isInViewport = (target: HTMLElement) => {
    const rect = target.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      (rect.top <=
        (window.innerHeight || document.documentElement.clientHeight) ||
        rect.bottom >=
          (window.innerHeight || document.documentElement.clientHeight))
    );
  };

  useEffect(() => {
    const updateActiveMenuLink = () => {
      let hasActive = false;
      setSrolled(
        (document.documentElement.scrollTop || document.body.scrollTop) > 70
      );
      document.querySelectorAll("header .menu-li").forEach((elem, i) => {
        elem.classList.remove("active");
        const link = elem.querySelector("a");
        const newHash = link?.getAttribute("href");
        const targetId = newHash?.replace("#", "");
        const target = targetId ? document.getElementById(targetId) : null;
        if (!hasActive && target && isInViewport(target)) {
          elem.classList.add("active");
          hasActive = true;
          if (newHash && window.location.hash !== newHash) {
            window.history.replaceState(undefined, "", newHash);
          }
        }
      });
    };

    updateActiveMenuLink();
    const onScroll: EventListener = (event: Event) => {
      updateActiveMenuLink();
    };
    const win: Window = window;
    win.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const closeResponsiveMenu = () => {
    setOpened(false);
  };

  const onResponsiveButtonClick = () => {
    setOpened(!opened);
  };

  return (
    <StyledHeader
      className={`${scrolled ? "scrolled" : ""} ${opened ? "opened" : ""}`}
    >
      <CentralContent>
        <div className="content">
          <div className="left">
            <Link to={ROUTES.HOMEPAGE}>
              <img alt="Parquets Lascazes logo" src={logo} />
            </Link>
          </div>

          <div className="right">
            <MenuBurgerButton
              opened={opened}
              onClick={onResponsiveButtonClick}
            />
            <ul className={opened ? "opened" : ""}>
              <li className="menu-li">
                <a
                  href={`${homepageUrl}#accueil`}
                  onClick={closeResponsiveMenu}
                >
                  Accueil
                </a>
              </li>
              <li className="menu-li">
                <a
                  href={`${homepageUrl}#nos-reseaux`}
                  onClick={closeResponsiveMenu}
                >
                  Nos réseaux
                </a>
              </li>
              <li className="menu-li">
                <a
                  href={`${homepageUrl}#contact`}
                  onClick={closeResponsiveMenu}
                >
                  Nous contacter
                </a>
              </li>
              <li className="menu-li">
                <a href={`${homepageUrl}#avis`} onClick={closeResponsiveMenu}>
                  Vos avis
                </a>
              </li>
            </ul>
          </div>
        </div>
      </CentralContent>
    </StyledHeader>
  );
};
