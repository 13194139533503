import styled from 'styled-components';
import { CentralContent } from './layout';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from '../config';


const StyledFooter = styled.footer`
  margin-top: auto;
  background-color: #fff;
  padding-block: 30px;

  @media all and (max-width: 560px) {
    & {
        padding-block: 20px;
    }
  }

  .content {
    position: relative;
    display: flex;

    .right {
      margin-left: auto;

      ul {
        list-style-type: none;
        display: flex;
        gap: 20px;
        height: 100%;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        li {
          display: inline-block;
          vertical-align: middle;

          a {
            text-decoration: none;
            color: var(--color-green);

            &:hover,
            &.active {
              color: var(--color-green-active);
            }
          }
        }
      }
    }

    @media all and (max-width: 670px) {
      & {
        flex-direction: column;

        .right {
          margin-left: 0;
          margin-top: 10px;

          ul {
            justify-content: left;
          }
        }
      }
    }
  }
`;

export const Footer = () => {

  const currentYear = useMemo(() => (new Date().getFullYear()), []);

  return (
    <StyledFooter>
      <CentralContent>
        <div className='content'>
          <div className='left'>
            Copyright {currentYear} © Tous droits réservés.
          </div>

          <div className='right'>
            <ul>
              <li>
                <Link to={ROUTES.CGV}>
                  Conditions générales de vente
                </Link>
              </li>
              <li>
                <Link to={ROUTES.LEGAL_MENTIONS}>
                  Mentions légales
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </CentralContent>
    </StyledFooter>
  );
}
