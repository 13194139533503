import styled from 'styled-components';

export const Container = styled.div`
  background: var(--white);
  box-flex: 1;
  padding: 24px;
  border-radius: var(--default-border-radius);
  border: 1px solid #dfdfdf;
`;

export const StyledTransparentContainer = styled(Container)`
  background: transparent;
  border: none;

  &.loading {
    opacity: 0.25;
    transition: opacity 200ms;
    transition-delay: 200ms;
  }
`;

type StyledCentralContainerProps = {
  maxWidth?: string,
};

export const StyledCentralContainer = styled(Container)<StyledCentralContainerProps>`
  width: 100%;
  margin: 15px;
  max-width: ${(props) => props.maxWidth || '320px'};
`;
