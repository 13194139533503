import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import utc from 'dayjs/plugin/utc';
import 'dayjs/locale/fr'; // load on demand

dayjs.locale('fr')
dayjs.extend(utc)
dayjs.extend(relativeTime);
dayjs.extend(updateLocale);
dayjs.updateLocale('fr', {
  relativeTime: {
    future: 'dans %s',
    past: (val: string) => {
        return (val === 'now') ? 'à l\'instant' : 'il y a %s'.replace('%s', val);
    },
    s: 'à l\'instant',
    m: '1 min',
    mm: '%d mins',
    h: '1 heure',
    hh: '%d heures',
    d: '1 jour',
    dd: '%d jours',
    M: '1 mois',
    MM: '%d mois',
    y: '1 an',
    yy: '%d ans'
  }
});

export const DAYJS_API_STR_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]';